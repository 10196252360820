:root {
    // TYPO
    --ff-default: "Montserrat", sans-serif;
    --ff-accent: "Bungee", cursive;

    // COLORS
    //
    // Grayscale Colors
    --color-black: #000;
    --color-grey-900: #222;
    --color-grey-500: #828282;
    --color-grey-200: #bdbdbd;
    --color-grey-100: #f2f2f2;
    --color-white: #fff;

    // Primary Colors
    --color-primary: #ee7f00;
    --color-primary-hover: #fe9925;
    --color-primary-25: rgba(238, 127, 0, 0.25);

    // Secondary Colors
    --color-secondary: #d4006f;
    --color-secondary-hover: #ff108d;

    // Status Colors
    --color-focus: #00c0d4;
    --color-warn: #ee7f00;
    --color-danger: #eb5757;
    --color-success: #2dc33c;
}
