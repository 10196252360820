input,
select {
    color: var(--color-black);
}

.formField {
    position: relative;
    border: 2px solid var(--color-primary);
    border-radius: 8px;
    padding-right: 6px;

    &.-focus {
        border-color: var(--color-focus);
    }

    &.-error {
        border-color: var(--color-danger);
    }
}

.label {
    display: inline;
    position: relative;
    top: 6px;
    left: 6px;
    padding: 0 4px;
    background-color: var(--color-white);
    z-index: 1;
    font-size: 12px;
    color: var(--color-primary);
    border-radius: 10px;
}

.control {
    width: 100%;
    height: 38px;
    padding: 6px;
    border: none;
    border-radius: 8px;
}

textarea.control {
    height: 150px;
    resize: none;
}

.error {
    display: block;
    margin-top: 8px;
    margin-left: 10px;
    color: var(--color-danger);
    font-size: 12px;
}

.visibleIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;
    display: flex;
    align-items: center;
}

input:disabled {
    color: rgba(128, 128, 128, 0.6);
}
input:disabled,
select:disabled {
    cursor: not-allowed;
    color: grey;
}

select {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAdgAAAHYBTnsmCAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAABVSURBVDiN7dCxDkBAFETRo9j4RJ3/sxIavk5BoxBhV1DubefOvORR+JUWESHhBPS7exkOmFC/yJPSo/JZnnc5YLwrV4mR7pCvaLDkrp9HovxjC1/ZAN3qDxFwDHgDAAAAAElFTkSuQmCC")
        no-repeat 100% 50%;
    appearance: none;
}
