@import "../../styles/settings/breakpoints.scss";

.tag {
    padding: 2px 4px;
    border: 2px solid;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 600;
    text-align: center;

    &.-success {
        border-color: var(--color-success);
        color: var(--color-success);

        &.-filled {
            color: var(--color-white);
            background-color: var(--color-success);
        }
    }

    &.-warn {
        border-color: var(--color-warn);
        color: var(--color-warn);

        &.-filled {
            color: var(--color-white);
            background-color: var(--color-warn);
        }
    }

    &.-danger {
        border-color: var(--color-danger);
        color: var(--color-danger);

        &.-filled {
            color: var(--color-white);
            background-color: var(--color-danger);
        }
    }

    &.-small {
        font-size: 11px;
    }
}
