.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
    padding: 8px;
}

.modal {
    position: relative;
    width: 100%;
    max-width: 960px;
    max-height: 75vh;
    padding: 28px;
    border-radius: 12px;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;

    min-height: auto;
    height: auto;

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;

        svg {
            display: block;
        }
    }

    &__content {
        width: 100%;
        overflow-y: auto;
        flex-grow: 1;
        max-height: calc(75vh - 56px);
        padding-bottom: 8px;
    }
}
