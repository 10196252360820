@import "../../styles/settings/breakpoints.scss";

.maintenance {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 1rem;
    backdrop-filter: blur(10px);
    width: 100%;

    @include mq($until: "md") {
        gap: 1rem;
    }

    & p {
        @include mq($until: "md") {
            font-size: 0.9rem;
        }
    }

    &__logo {
        @include mq($until: "md") {
            max-width: 200px;
        }
    }

    & strong {
        font-family: var(--ff-accent);
        font-size: 1.2rem;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        isolation: isolate;
        height: 100%;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: -1;
        opacity: 0.5;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        text-align: center;
        max-width: 768px;
    }

    &__video {
        width: 100%;
        aspect-ratio: 16/9;
        margin-inline: auto;
    }
}
