.statusMessage {
    padding: 8px 16px;
    border: 2px solid;
    border-radius: 4px;
    font-weight: 600;

    &.-success {
        border-color: var(--color-success);
        color: var(--color-success);
    }

    &.-warn {
        border-color: var(--color-warn);
        color: var(--color-warn);
    }

    &.-danger {
        border-color: var(--color-danger);
        color: var(--color-danger);
    }

    & + & {
        margin-top: 0.5rem;
    }
}
