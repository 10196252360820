@import "../../styles/settings/breakpoints.scss";

.header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-primary);
    background: var(--color-white);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    @include mq($from: "lg") {
        height: 88px;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 8px 10px;
        gap: 8px;

        @include mq($from: "lg") {
            padding: 8px 20px;
        }
    }

    &__logo {
        margin-right: 16px;
        flex-shrink: 0;
        cursor: pointer;
    }
}

.logo {
    width: 100px;

    @include mq($from: "lg") {
        width: 192px;
    }
}

.nav {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.menu {
    margin-right: 20px;
    margin-left: 20px;

    &__item {
        display: flex;
        color: var(--color-black);
        text-decoration: none;

        &:hover,
        &:focus {
            color: var(--color-primary);
        }

        & + & {
            margin-left: 20px;
        }

        & svg {
            width: 25px;

            @include mq($from: "lg") {
                width: 30px;
            }
        }
    }

    &__account {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 13px;
        color: rgb(124, 120, 106);
        cursor: pointer;

        & svg {
            width: 20px;
        }

        &:hover {
            color: var(--color-black);
        }
    }
}

.form {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    &:hover {
        select {
            color: var(--color-black);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17px' height='17px' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.6' d='m6 9l6 6l6-6'/%3E%3C/svg%3E");
        }
        .langIcon {
            color: var(--color-black);
        }
    }
}

.language {
    & > div {
        border: none;
    }

    select {
        cursor: pointer;
        font-size: 13px;
        min-width: 5rem;
        color: rgb(124, 120, 106);
        line-height: 1.5;
        appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17px' height='17px' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='rgb(124, 120, 106)' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.6' d='m6 9l6 6l6-6'/%3E%3C/svg%3E");

        @include mq($until: "md") {
            height: 29px;
        }
    }
}

.langIcon {
    width: 19px;
    height: 19px;
    color: rgb(124, 120, 106);
    position: relative;
    top: -1px;
}

.formBox {
    display: none;
    position: relative;
    top: -10px;
    flex-grow: 1;

    &__submit {
        height: 42px;
        margin-left: 8px;
        box-shadow: none !important;
    }

    @include mq($from: "xlg") {
        display: block;
    }
}

.cancelOverlay {
    background-color: var(--color-secondary) !important;
    background: linear-gradient(
            135deg,
            var(--color-primary) 0%,
            rgba(255, 255, 255, 0) 100%
        ),
        var(--color-secondary);

    svg {
        fill: var(--color-white);
    }
}
