body {
    font-family: var(--ff-default);
    font-size: 1rem;

    &.-noScroll {
        height: 100%;
        overflow: hidden;
    }
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;

    & main {
        flex: 1 0 auto;
    }
}

html,
body {
    height: 100%;
}
