@import "../../styles/settings/breakpoints.scss";

.button {
    padding: 8px 16px;
    border: 2px solid;
    border-radius: 4px;
    font-size: 18px;
    box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease-in-out;

    @include mq($until: "md") {
        font-size: 16px;
        padding: 4px 8px;
    }

    &.-primary {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
        color: var(--color-white);

        &:hover,
        &:focus {
            border-color: var(--color-primary-hover);
            background-color: var(--color-primary-hover);
        }

        &:disabled {
            cursor: not-allowed;
            border-color: var(--color-grey-200);
            background-color: var(--color-white);
            color: var(--color-grey-500);
        }
    }

    &.-secondary {
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        color: var(--color-white);

        &:hover,
        &:focus {
            border-color: var(--color-secondary-hover);
            background-color: var(--color-secondary-hover);
        }
    }

    &.-grey {
        background-color: var(--color-grey-200);
        border-color: var(--color-grey-200);
        color: var(--color-white);

        &:hover,
        &:focus {
            border-color: var(--color-grey-500);
            background-color: var(--color-grey-500);
        }
    }

    &.-darkgrey {
        background-color: var(--color-grey-500);
        border-color: var(--color-grey-500);
        color: var(--color-white);

        &:hover,
        &:focus {
            border-color: var(--color-grey-900);
            background-color: var(--color-grey-900);
        }
    }

    &.-outlined {
        background-color: transparent;
        color: var(--color-black);
        box-shadow: none;

        &:hover,
        &:focus {
            color: var(--color-white);
        }
    }

    &.-small {
        font-size: 14px;
        padding: 4px 8px;
    }
}
