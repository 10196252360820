$checkboxSize: 20px;

.checkbox {
    display: flex;
    cursor: pointer;
}

.control {
    display: none;
}

.fake {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $checkboxSize;
    height: $checkboxSize;
    margin-right: 10px;
    border: 1px solid var(--color-grey-200);
    border-radius: 3px;
    cursor: pointer;
}

input[type="checkbox"]:checked + .fake {
    border-color: var(--color-primary);
}

input[type="checkbox"]:checked + .fake::after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background-color: var(--color-primary);
    border-radius: 3px;
}

.error {
    display: block;
    margin-top: 4px;
    color: var(--color-danger);
    font-size: 12px;
}

.label {
    padding-top: 2px;
}
