h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--ff-accent);
    font-weight: 400;
}

b,
strong {
    font-weight: 600;
}
