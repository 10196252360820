.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50vh;
    transform: translateY(-50%);
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.loader {
    margin: 24px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid rgba(0, 0, 0, 0.1);
    border-right: 5px solid rgba(0, 0, 0, 0.1);
    border-bottom: 5px solid rgba(0, 0, 0, 0.1);
    border-left: 5px solid var(--color-primary);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loaderSpin 1.1s infinite linear;
    animation: loaderSpin 1.1s infinite linear;
}

@-webkit-keyframes loaderSpin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loaderSpin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
