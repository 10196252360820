@import "../../styles/settings/breakpoints.scss";

.fileUpload {
    padding: 30px 20px;
    border: 1px solid var(--color-grey-200);
    border-radius: 10px;
    cursor: pointer;

    &.-error {
        border: 2px solid var(--color-danger);

        .hint {
            color: var(--color-danger);
        }
    }
}

.wrapper {
    display: flex;
    align-items: center;
}

.icon {
    flex-shrink: 0;
    margin-right: 20px;
}

.fileList {
    margin-top: 20px;
    list-style: none;

    @include mq($from: "md") {
        margin-left: 58px;
    }

    li {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        color: var(--color-primary);
        font-size: 14px;
    }

    button {
        margin-right: 5px;
    }

    svg {
        display: block;
        fill: var(--color-grey-200);
    }

    small {
        display: none;
        font-size: 12px;
        color: var(--color-black);
        margin-left: 4px;

        @include mq($from: "md") {
            display: block;
        }
    }
}

.errorList {
    margin-top: 20px;
    list-style: none;

    @include mq($from: "md") {
        margin-left: 58px;
    }

    & li {
        color: var(--color-danger);
    }
}

.hint {
    margin-top: 6px;
    font-size: 12px;
    color: var(--color-grey-200);

    &.-error {
        color: var(--color-danger);
    }
}
