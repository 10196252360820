@import "../../styles/settings/breakpoints.scss";

.footer {
    width: 100%;
    padding: 40px 20px;
    border-top: 1px solid #444;
    background-color: var(--color-grey-900);
    color: var(--color-white);

    @include mq($until: "lg") {
        padding: 30px 5px;
    }

    &__logo {
        width: 100px;

        @include mq($from: "lg") {
            width: 192px;
        }
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr;

    @include mq($from: "md") {
        grid-template-columns: 1fr 1.5fr;
    }
    @include mq($from: "lg") {
        grid-template-columns: 1fr 1fr 1.5fr;
        gap: 20px;
    }
}

.columnTwo {
    @include mq($until: "lg") {
        display: none;
    }
}

.columnOne {
    @include mq($until: "md") {
        display: none;
    }
    @include mq($from: "md") {
        display: block;
    }
}

.columnThree {
    display: flex;
    flex-direction: column;
    gap: 20px;

    div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: right;
    }

    img {
        display: block;
        align-self: flex-end;
    }

    a {
        color: var(--color-white);
        text-decoration: none;

        &:hover,
        &:focus {
            color: var(--color-grey-200);
            text-decoration: underline;
        }
    }
}

.contact {
    @include mq($until: "md") {
        font-size: 14px;
    }

    &__nobreak {
        display: inline-block;
    }
}
