.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-white);
}

.icon {
    margin-bottom: 30px;

    svg {
        display: block;
        width: 150px;
        height: 150px;
        fill: var(--color-secondary);
    }
}

.message {
    width: 264px;
    margin-bottom: 48px;
    font-size: 15px;
    line-height: 1.4;
    text-align: center;
}
