.MuiStepIcon-root.MuiStepIcon-completed {
    color: var(--color-success) !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
    color: var(--color-secondary) !important;
}
.MuiStepContent-root {
    margin-top: 0 !important;
    margin-left: 14px !important;
    padding-left: 36px !important;
    border-left: 4px solid var(--color-grey-200) !important;
}
.MuiStepLabel-iconContainer {
    padding-right: 20px !important;
}
.MuiStepConnector-vertical {
    padding: 0 !important;
    margin-left: 14px !important;
}
.MuiStepConnector-lineVertical {
    border-left-width: 4px !important;
}
.MuiStepConnector-line {
    border-color: var(--color-grey-200) !important;
}

.MuiSvgIcon-root {
    width: 32px !important;
    height: 32px !important;
    font-size: 32px !important;
    color: var(--color-grey-200) !important;
}

.MuiStepIcon-text {
    font-family: var(--ff-accent) !important;
}
