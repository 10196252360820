@import "../../styles/settings/breakpoints.scss";

.searchForm {
    @include mq($from: "lg") {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        &.-error {
            align-items: flex-start;

            & button[type="submit"] {
                margin-top: 18px;
            }
        }
    }

    &__fields {
        flex-grow: 1;
        display: grid;
        gap: 6px;
        grid-template-columns: 1fr;

        @include mq($from: "lg") {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        & > div {
            min-width: 12rem;
        }
    }
}

.error {
    display: block;
    color: red;
    margin-top: 1rem;
    text-align: center;
}
